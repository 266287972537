import React, { useState, useEffect, useCallback } from "react"
import { useFormik } from 'formik';
import { initialValues, validationSchema } from './formik';
import HeaderProgress from "../../components/HeaderProgress"
// GraphQL
import client from "../../service/graphql"
import mutations from "../../service/mutations"
import { navigate } from "gatsby"
import Animation from '../../images/animation.gif'
import IconDenied from '../../images/icon-denied.png'

import {
  Container,
  Section,
  Image,
  Body,
  TextTitle,
  TextSubTitle,
  ContainerDenied,
  TitleDenied,
  SubTitleDenied,
  ImageDenied,
  Button
} from "./styles"
import { Buttons } from "../../steps/shared-styles"

const SignUp = ({ cpf, deal = null, route }) => {
  const [error, setError] = useState(null)
  const [denied, setDenied] = useState(false)

  const handleConsultationHirp = useCallback(async () => {
    try {
      setError(null)
      const response = await client.request(mutations.CONSULTATE_SERASA, {
        input: {
          document_number: cpf,
        },
      });
      const { payload } = response.consultateSerasa;

      const score = JSON.parse(JSON.parse(payload));

      if (Number(score.score) < 7) {
        setDenied(true);
      } else {
        navigate(route, { state: { cpf, deal }})
      }
    } catch (errors) {
      setError("ocorreu um erro inesperado");
    }
  }, [client, cpf]);

  useEffect(() => {
    setTimeout(() => {
      handleConsultationHirp();
    }, 2000);
  }, []);
  
  return (
    <div>
      <HeaderProgress description="Sobre você" percent={50} />
      <Container padding>
        <Section>
          <Body>
            {denied ? (
              <ContainerDenied>
                <ImageDenied src={IconDenied} />
                <TitleDenied>Ainda não podemos te oferecer um empréstimo.</TitleDenied>
                <SubTitleDenied>
                  Com base na nossa análise, infelizmente, neste momento não podemos te oferecer um empréstimo. Mas não desanime, você pode fazer uma nova solicitação de crédito em 3 meses.
                </SubTitleDenied>
              </ContainerDenied>
            ) : (
              <>
                <Image src={Animation} />
                <TextTitle>Aguarde um momento</TextTitle>
                <TextSubTitle>Estamos analisando seu cadastro</TextSubTitle>
              </>
            )}
          </Body>
        </Section>
      </Container>
    </div>
  )
}

export default SignUp
