import * as Yup from 'yup';
import { cpf } from 'cpf-cnpj-validator';

export const initialValues = {
  cpf: '',
};

export const validationSchema = Yup.object().shape({
  cpf: Yup.string()
    .test('CPF Test', 'Informe um cpf válido', (value) => cpf.isValid(value))
    .required('O campo cpf é obrigatório'),
});
