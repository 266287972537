import React, { useEffect, useState } from 'react';

import Layout from "../components/layout"
import SEO from "../components/seo"

// Sections
import SerasaAnalytics from "../sections/serasaAnalytics"

function SerasaAnalyticsPage({ location }) {
  console.log('location', location)
  return (
    <Layout>
      <SEO title="Cadastro" />
      <SerasaAnalytics cpf={location.state?.cpf} deal={location.state?.deal} route={location.state?.route} />
    </Layout>
  );
}

export default SerasaAnalyticsPage;